<template>
  <Tabs v-model="parcelTabs" layout="simple">
    <Tab title="Informations">
      <Container>
        <h3>Informations</h3>
      </Container>
      <div class="key-values key-values--page-bottom">
        <KeyValue table label="Nom de la parcelle" :value="parcel?.designation" />
        <KeyValue table label="Nom de l'agriculteur" :value="parcel?.nom_agriculteur" />
        <KeyValue table label="Adresse" :value="parcel?.adresse" />
        <KeyValue table label="Code postal" :value="parcel?.cp" />
        <KeyValue table label="Ville" :value="parcel?.ville" />
        <KeyValue table label="Coordonnées GPS"
          :value="`${parcel?.latitude ?? 'Non renseigné'} / ${parcel?.longitude ?? 'Non renseigné'}`" />
        <KeyValue table label="Téléphone" :value="parcel?.tel" />
        <KeyValue table label="Mail" :value="parcel?.email" />
        <KeyValue table label="Ville de la station météo la plus proche" :value="parcel?.villemeteo" />
        <KeyValue table label="Code postale station météo la plus proche" :value="parcel?.cpmeteo" />
      </div>
      <template v-if="map">
        <Container class="margin-top">
          <h3 class="h3-map">Visualisation du plan</h3>
        </Container>
        <div v-if="parcel?.latitude && parcel?.longitude" class="map" id="map"></div>
        <div class="map">
          <ImageToken defaultImage :image="parcel?.media" size="full" />
        </div>
      </template>
    </Tab>

    <Tab title="Sol">
      <Container>
        <h3>Sol</h3>
      </Container>
      <div class="key-values key-values--page-bottom">
        <KeyValue table label="Type de sol" :value="parcel_sheet?.sol_type?.valeur" />
        <KeyValue table label="Argile" :value="parcel_sheet?.argile ? parcel_sheet?.argile + '%' : null" />
        <KeyValue table label="Limon" :value="parcel_sheet?.limon ? parcel_sheet?.limon + '%' : null" />
        <KeyValue table label="Sable" :value="parcel_sheet?.sable ? parcel_sheet?.sable + '%' : null" />
        <KeyValue table label="Cailloux" :value="parcel_sheet?.cailloux?.valeur" />
        <KeyValue table label="Profondeur de sol" :value="parcel_sheet?.sol_profondeur?.valeur" />
        <KeyValue table label="Réserve utile" :value="parcel_sheet?.reserve_utile?.valeur" />
        <KeyValue table label="Exposition" :value="parcel_sheet?.exposition" />
        <KeyValue table label="Sensibilité à la sécheresse" :value="helperService.displayBoolean(parcel_sheet?.sensibilite_secheresse)
          " />
        <KeyValue table label="Sensibilité à l’excès d’eau" :value="helperService.displayBoolean(parcel_sheet?.sensibilite_exces_eau)
          " />
        <KeyValue table label="Irrigation" :value="helperService.displayBoolean(parcel_sheet?.irrigation)" />
        <KeyValue table label="Drainage" :value="helperService.displayBoolean(parcel_sheet?.drainage)" />
        <KeyValue table label="Sol en pente" :value="helperService.displayBoolean(parcel_sheet?.sol_en_pente)" />
        <KeyValue table label="Analyse du sol disponible"
          :value="helperService.displayBoolean(parcel_sheet?.analyse_sol_dispo)" />
        <KeyValue table label="Date d’analyse du sol"
          :value="helperService.displayDate(parcel_sheet?.date_analyse_sol)" />
        <KeyValue class="key-value--break-word" table label=" Situation particulière"
          :value="parcel_sheet?.situation_particuliere" />
        <KeyValue table label="Analyse de sol">
          <ImageToken v-if="['jpg', 'jpeg', 'png', 'svg'].includes(parcel_sheet?.media?.extension)"
            :image="parcel_sheet?.media" :alt="parcel_sheet?.nom" size="full" />
          <p v-else>
            <a v-if="mediaLink" target="_blank" :href="mediaLink">Voir le fichier</a>
            <template v-else>Non renseigné</template>
          </p>
        </KeyValue>
      </div>
    </Tab>
    <Tab title="Culture">
      <Container>
        <h3>Culture</h3>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Culture" :value="parcel_sheet?.culture?.nom" />
        <KeyValue table label="Variété" :value="parcel_sheet?.variete" />
        <KeyValue table label="Date de semis" :value="helperService.displayDate(parcel_sheet?.date_semis)" />
        <KeyValue table label="Densité de semis" :value="parcel_sheet?.densitesemis
            ? parcel_sheet?.densitesemis +
            ' ' +
            parcel_sheet?.densite_semis?.valeur
            : null
          " />
        <KeyValue table label="Ecartement des rangs" :value="parcel_sheet?.ecartement_rangs" />
        <KeyValue table label="Conditions de semis" :value="parcel_sheet?.conditionssemis" />
        <KeyValue table label="Objectif de rendement" :value="parcel_sheet?.objectifs_rendement
            ? parcel_sheet?.objectifs_rendement +
            ' ' +
            parcel_sheet?.unite?.valeur
            : null
          " />
        <KeyValue table label="Date de levée" :value="helperService.displayDate(parcel_sheet?.date_levee)" />
        <KeyValue table label="Précédent cultural" :value="parcel_sheet?.culture_precedante?.nom" />
        <KeyValue table label="Travail du sol" :value="parcel_sheet?.sol_travail?.valeur" />
        <KeyValue table label="Culture en production"
          :value="helperService.displayBoolean(parcel_sheet?.cultureproduction)" />
      </div>

      <Container>
        <h4>Fumure ou fertilisation</h4>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Engrais minéraux" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.fumure_mineraux_date)" />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.fumure_mineraux_produits" />

        <KeyValue table label="Engrais organiques" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.fumure_organique_date)" />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.fumure_organique_produits" />

        <KeyValue table label="Amendements calcaires" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.fumure_calcaire_date)" />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.fumure_calcaire_produits" />
        <KeyValue table label="Oligoéléments" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.fumure_oligo_date)" />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.fumure_oligo_produits" />
      </div>

      <Container>
        <h4>Protection des cultures</h4>
      </Container>
      <div class="key-values key-values--page-bottom">
        <KeyValue table label="Herbicides" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.protection_herbicides_date)
          " />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.protection_herbicides_produits" />

        <KeyValue table label="Insecticides" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.protection_inscticides_date)
          " />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.protection_insecticides_produits" />

        <KeyValue table label="Fongicides" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.protection_fongicides_date)
          " />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.protection_fongicides_produits" />
        <KeyValue table label="Régulateurs" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.protection_regulateurs_date)
          " />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.protection_regulateurs_produits" />
        <KeyValue table label="Autres" value=" " />
        <KeyValue table child label="Date" :value="helperService.displayDate(parcel_sheet?.protection_autres_date)
          " />
        <KeyValue table child label="Produits / quantités" :value="parcel_sheet?.protection_autres_produits" />
      </div>
    </Tab>
  </Tabs>
</template>

<script>
import { ref } from 'vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Tabs from '@/components/base/Tabs.vue'
import Tab from '@/components/base/Tab.vue'
import Container from '@/components/layout/Container.vue'
import ImageToken from '@/components/base/ImageToken.vue'
import L from 'leaflet'

export default {
  name: 'Parcel',
  components: {
    KeyValue,
    Tabs,
    Tab,
    Container,
    ImageToken,
  },
  props: {
    parcel: {
      type: Object,
    },
    parcel_sheet: {
      type: Object,
    },
    map: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const parcelTabs = ref(0)
    return {
      parcelTabs,
    }
  },
  data() {
    return {
      myMap: null,
      center: [],
      positions: [],
      mediaLink: null,
    }
  },
  mounted() {
    if (
      this.map
      && this.parcel?.latitude
      && this.parcel?.longitude
    ) {
      this.center = [this.parcel?.latitude, this.parcel?.longitude]
      this.positions.push({
        lat: this.parcel?.latitude,
        lon: this.parcel?.longitude,
      })
      this.setupLeafletMap()
    }

    if (this.parcel_sheet && this.parcel_sheet.media && 'jeton' in this.parcel_sheet.media) {
      this.getMediaLink()
    }
  },
  methods: {
    getMediaLink() {
      this.fileService.displayImage(this.parcel_sheet.media.jeton).then((response) => {
        this.mediaLink = response.data
      })
    },
    setupLeafletMap() {
      const LeafIcon = L.Icon.extend({})
      const blueIcon = new LeafIcon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        shadowUrl:
          'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
      })

      this.myMap = L.map('map').setView(this.center, 13)
      L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
        attribution:
          'données © <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>',
        minZoom: 1,
        maxZoom: 20,
      }).addTo(this.myMap)

      this.myMap.zoomControl.setPosition('bottomleft')

      this.positions.forEach((position) => {
        const marker = L.marker([position.lat, position.lon], {
          icon: blueIcon,
          title: this.parcel?.designation,
          alt: this.parcel?.designation,
        }).addTo(this.myMap)

        marker.bindPopup(position)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.image-token {
  margin-top: $gutter;
  width: 100%;
}

.map {
  margin-top: $gutter;
  width: 100%;
}

#map {
  height: 480px;
}
</style>
